<template>
  <partner-admin-page-card
    pageName="Partner Groups"
    iconClass="fa fa-users fa-lg"
  >
    <v-wait for="fetchingGroups">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-3 offset-sm-6 mb-3 text-right">
          <router-link
            tag="button"
            class="btn btn-primary"
            :to="{ path: '/partner_admin/groups/new' }"
            >Add New Group</router-link
          >
        </div>
        <div class="col-sm-3 mb-3">
          <b-input-group>
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search fa-lg"></i>
              </span>
            </div>
            <b-form-input
              type="text"
              v-model="filterText"
              placeholder="Search"
              ref="search"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="col-sm-12">
        <b-table
          :items="listingGroups"
          :fields="tableFields"
          :show-empty="true"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filterText"
          :filter-included-fields="filterOn"
          @filtered="afterFilter"
          empty-text="No records found."
          responsive="md"
          striped
          hover
        >
          <template v-slot:cell(fdic_certificate_number)="data">
            {{ data.item.fi_id ? data.item.fdic_certificate_number : 1 }}
          </template>
          <template v-slot:cell(actions)="data">
            <router-link
              :to="{
                name: 'PartnerAdminEditGroup',
                params: { id: data.item.id }
              }"
              tag="div"
              :class="[
                data.item.fdic_certificate_number ? '' : 'tag-disabled',
                'btn btn-primary btn-sm mx-2'
              ]"
              :disabled="!data.item.fdic_certificate_number"
            >
              Edit
            </router-link>
            <div
              @click="setSelectedGroup(data.item)"
              v-b-modal.deleteConfirmation
              :class="[
                data.item.fdic_certificate_number ? '' : 'tag-disabled',
                'btn btn-secondary btn-sm mx-2'
              ]"
              :disabled="!data.item.fdic_certificate_number"
            >
              Delete
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col md="6">
            <b-pagination
              :total-rows="filteredGroups ? filteredGroups.length : totalRows"
              :per-page="perPage"
              v-model="currentPage"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        id="deleteConfirmation"
        ref="deleteConfirmation"
        title="Delete Group"
        @ok="deleteGroup"
        @cancel="resetSelectedGroup"
      >
        <p>Are you sure, want to delete the group?</p>
      </b-modal>
    </v-wait>
  </partner-admin-page-card>
</template>

<script charset="utf-8">
// global
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
// api
import partnerGroupsAPI from '@/api/finapps/partner_admin/groups'

export default {
  name: 'PartnerGroups',
  beforeCreate() {
    let hasPermission = store.getters['Ability/managePartnerAdminBoard']

    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.fetchPartnerGroups()
  },
  data() {
    return {
      groups: [],
      currentPage: 1,
      perPage: 20,
      selectedGroup: null,
      filterText: null,
      filteredGroups: null,
      filterOn: ['name', 'fdic_certificate_number', 'fi_type']
    }
  },
  computed: {
    listingGroups() {
      return this.groups.filter(group => !group.isDeleted)
    },
    totalRows() {
      return this.listingGroups.length
    },
    tableFields() {
      return [
        { key: 'name', label: 'Name', sortable: true },
        {
          key: 'fdic_certificate_number',
          label: 'Certifcate Number',
          sortable: true
        },
        { key: 'fi_type', label: 'FI Type', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    }
  },
  methods: {
    afterFilter: function(filteredItems) {
      this.filteredGroups = filteredItems
      this.currentPage = 1
    },
    setSelectedGroup(group) {
      this.selectedGroup = group
    },
    resetSelectedGroup() {
      this.selectedGroup = null
    },
    fetchPartnerGroups() {
      this.$wait.start('fetchingGroups')

      partnerGroupsAPI
        .all()
        .then(res => {
          res.groups.forEach(group => (group.isDeleted = false))
          this.groups = res.groups
        })
        .finally(() => {
          this.$wait.end('fetchingGroups')
        })
    },
    deleteGroup(evt) {
      evt.preventDefault()

      partnerGroupsAPI.deleteGroup(this.selectedGroup.id).then(res => {
        this.setSelectedGroup({})
        this.maskGroupAsDeleted(res.group)
        this.$refs.deleteConfirmation.hide()
        Vue.toasted.show(res.message, {
          icon: 'user-circle',
          type: 'success'
        })
      })
    },
    maskGroupAsDeleted(groupId) {
      let group = this.groups.find(group => group.id === groupId)
      group.isDeleted = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
