import { render, staticRenderFns } from "./Groups.vue?vue&type=template&id=5a7387a2&scoped=true&"
import script from "./Groups.vue?vue&type=script&charset=utf-8&lang=js&"
export * from "./Groups.vue?vue&type=script&charset=utf-8&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a7387a2",
  null
  
)

export default component.exports